/**
 * Appel une fonction après que le document est chargé.
 * @param {Function} fn - La fonction à appeler
 * @returns {void}
 */
export default (fn) => {
  if (typeof fn !== 'function') {
    return;
  }

  // si le document est déjà chargé, on appel la fonction
  if (document.readyState === 'interactive' ||
      document.readyState === 'complete') {
    fn();
  }

  document.addEventListener('DOMContentLoaded', fn, false);
};
