import ready from './helpers/ready';

/**
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const Message = {
  ACTIVE: 'Votre visite sur ce site est actuellement <b>suivie anonymement</b>.',
  ACTIVE_HELP: 'Vous pouvez vous opposer au suivi en décochant cette case.',
  INACTIVE: 'Votre visite sur ce site n\'est actuellement <b>pas suivie</b>.',
  INACTIVE_HELP: 'Vous pouvez activer le suivi anonyme en cochant cette case afin de nous aider à améliorer notre site.',
  DONOTTRACK: 'Votre visite sur ce site n\'est <b>pas suivie</b>, conformément aux préférences de votre navigateur.',
  DONOTTRACK_HELP: 'La fonctionnalité <i>Do Not Track</i> étant activée dans les préférences de votre navigateur, elle prend le dessus sur le contrôle de votre suivi sur ce site.',
  OLDBROWSER_HELP: 'Votre navigateur semble trop vieux pour vous permettre de contrôler votre suivi. Pour plus de sécurité et de confidentialité, veuillez mettre à jour votre navigateur.'
};

const Default = {
  key: 'tracking',
  initial: true
};

const Selector = {
  CONTAINER: '.tracking-optout',
  CHECKBOX: 'input[type="checkbox"]',
  STATE: '.tracking-state',
  HELP: '.tracking-help'
};

/**
 * ------------------------------------------------------------------------
 * Helpers
 * ------------------------------------------------------------------------
 */

/**
 * Detects whether localStorage is both supported and available.
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
 * @returns {Boolean} `true` if localStorage is usable.
 */
function hasLocalStorage() {
  let storage;
  try {
    storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 || // eslint-disable-line no-magic-numbers
      // Firefox
      e.code === 1014 || // eslint-disable-line no-magic-numbers
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
}

/**
 * Checks whether the user's do-not-track setting is enabled.
 * @returns {Boolean} `true` if the user should not be tracked.
 */
function hasDoNotTrack() {
  return navigator.doNotTrack === '1' ||
    navigator.doNotTrack === 'yes' ||
    navigator.msDoNotTrack === '1' ||
    window.doNotTrack === '1';
}

/**
 * ------------------------------------------------------------------------
 * Classe
 * ------------------------------------------------------------------------
 */

class OptedTracking {
  constructor(fn, config) {
    if (typeof fn !== 'function') {
      throw new Error('Une fonction est attendue comme premier argument');
    }

    this._elements = [];
    this._config = Object.assign({}, Default, config);

    this._dnt = hasDoNotTrack();
    this._localStorage = hasLocalStorage();

    this._enabled = this.getState();

    // exécute la fonction dès que possible
    if (this._enabled) {
      setTimeout(fn, 0);
    }

    window.addEventListener('storage', (e) => {
      if (e.key === this._config.key) {
        this._refresh();
      }
    });

    // attends que la page soit chargée pour initialiser les éléments
    ready(() => this._initElements());
  }

  // Méthodes publiques

  getState() {
    if (this._dnt) {
      return false;
    } else if (!this._localStorage) {
      return this._config.initial;
    }

    const state = window.localStorage.getItem(this._config.key);

    if (state === 'true') {
      return true;
    } else if (state === 'false') {
      return false;
    }

    return this._config.initial;
  }

  setState(enabled) {
    window.localStorage.setItem(this._config.key, enabled ? 'true' : 'false');
    // l'état étant changé dans ce contexte, il faut rafraichir manuellement
    this._refresh();
  }

  // Méthodes privées

  _refresh() {
    const enabled = this.getState();

    if (this._dnt) {
      this._adjustElements(
        Message.DONOTTRACK,
        Message.DONOTTRACK_HELP,
        enabled,
        true
      );
    } else if (!this._localStorage) {
      this._adjustElements(
        enabled ? Message.ACTIVE : Message.INACTIVE,
        Message.OLDBROWSER_HELP,
        enabled,
        true
      );
    } else {
      this._adjustElements(
        enabled ? Message.ACTIVE : Message.INACTIVE,
        enabled ? Message.ACTIVE_HELP : Message.INACTIVE_HELP,
        enabled,
        false
      );
    }

    // si l'état a changé, il faut recharger la page
    if (enabled !== this._enabled) {
      window.location.reload();
    }
  }

  _initElements() {
    this._elements = [...document.querySelectorAll(Selector.CONTAINER)];

    this._checkboxes = [];
    this._states = [];
    this._helps = [];

    // parcours tous les conteneurs et ajoute leurs éléments
    this._elements.forEach((container) => {
      const checkbox = container.querySelector(Selector.CHECKBOX);
      const state = container.querySelector(Selector.STATE);
      const help = container.querySelector(Selector.HELP);

      if (checkbox) {
        this._checkboxes.push(checkbox);
      }
      if (state) {
        this._states.push(state);
      }
      if (help) {
        this._helps.push(help);
      }
    });

    this._checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('click', () => this.setState(checkbox.checked));
    });

    this._refresh();
  }

  _adjustElements(stateText, helpText, checked, disabled) {
    if (stateText) {
      this._states.forEach((el) => {
        el.innerHTML = stateText;
      });
    }

    if (helpText) {
      this._helps.forEach((el) => {
        el.innerHTML = helpText;
      });
    }

    if (typeof checked === 'boolean') {
      this._checkboxes.forEach((el) => {
        el.checked = checked;
      });
    }

    if (disabled === true) {
      this._checkboxes.forEach((el) => el.setAttribute('disabled', ''));
    } else if (disabled === false) {
      this._checkboxes.forEach((el) => el.removeAttribute('disabled'));
    }
  }
}

/**
 * ------------------------------------------------------------------------
 * Export
 * ------------------------------------------------------------------------
 */

export default OptedTracking;

window.optedtracking = function (fn, config) {
  return new OptedTracking(fn.bind(window), config);
};
